import React, { ReactElement } from "react";
import MainBanner from "@components/debtreliefoffice/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import DebtReliefOfficeWrapper from "@components/layouts/debtReliefOfficeWrapper";
import OffersList from "@components/shared/offersList";
import WhatIsDebtRelief from "@components/debtreliefoffice/whatIsDebtRelief";
import WhatNext from "@components/debtreliefoffice/whatNext";
import WhySeekDebtRelief from "@components/debtreliefoffice/whySeekDebtRelief";
import DebtFreeLife from "@components/debtreliefoffice/debtFreeLife";

export default function DebtReliefOfficeHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <DebtReliefOfficeWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhySeekDebtRelief />
            <WhatIsDebtRelief />
            <WhatNext />
            <DebtFreeLife />
        </DebtReliefOfficeWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
